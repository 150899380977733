.leftText {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 90px;
  line-height: 116px;
  color: #344767;
  padding: 80px;
  text-align: left;
  margin-left: 110px;
}

.labelCss {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
}

.welcomeCss {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 24px;
  color: #000000;
}

.backCss {
  font-family: "Futura";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 24px;
  color: #000000;
}

.passwordInput {
  position: relative;
}

.eyeIcon {
  position: absolute;
  right: 7px;
  top: 32px;
}